.dropdown-animation {
  animation: dropdown 0.5s ease-out;
}

@keyframes dropdown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.menu-mobile-animation {
  animation: menu-mobile 0.5s ease-out;
}

@keyframes menu-mobile {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.menu-sidebar-animation {
  animation: menu-sidebar 0.7s ease;
}

@keyframes menu-sidebar {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .dropdown-animation {
    animation: none;
  }
}
