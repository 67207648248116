body {
	font: normal normal normal 24px/36px;
	letter-spacing: 0.48px;
	color: #202020;
	margin: 0;
	font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	  sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.hiddenBody {
	overflow: hidden;
}

.menu-mobile {
	display: none;
}

.button_menu_mobile {
	display: none;
}

.content-menu-link {
	display: flex;
	justify-content: center;
	align-items: center;
}

.links-menu {
	color: #fff;
	font-size: 1.25rem;
	padding: 0 1rem;
}

.perfil-menu {
	display: flex;
	margin-left: auto;
}

.perfil-menu button {
	height: 100%;
}


@media (max-width: 1024px) {
	.button_menu_mobile {
		display: flex;
	}

	.bg-header-mobile {
		background: #01448c;
	}

	.menu-mobile {
		position: fixed;
		background: #01448c;
		top: 4rem;
		right: 0;
		left: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		z-index: 1;
	}

	.content-menu-link {
		flex-direction: column;
	}

	.links-menu {
		padding: 1rem 0;
	}

	.perfil-menu {
		margin-left: 0;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.perfil-menu button {
		width: 6rem;
		height: auto;
	}
	
}